import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"

import Introduction from "../components/Home/Introduction"
import About from "../components/Home/About"
import Services from "../components/Home/Services"
import General from "../components/Home/General"
import CallToAction from "../components/Home/CallToAction"
import Categories from "../components/Home/Categories"
import Layout from "../components/Layout"

function HomeTemplate(props) {
    console.log(props)
    let data = props.data.wpgraphql;

    // useEffect(() => {
    //     const observer = new PerformanceObserver((list) => {
    //         let perfEntries = list.getEntries();
    //         let lastEntry = perfEntries[perfEntries.length - 1];
    //         console.log(lastEntry)
    //         console.log(lastEntry.element )
    //         // Process the latest candidate for largest contentful paint
    //       });
    //     observer.observe({entryTypes: ['largest-contentful-paint']});
    // },[])

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    console.log(data)
    return (
        <Layout 
            header={data.menu} 
            footer={data.footer} 
            services={data.services} 
            productCategories={data.productCategories} 
            metaData={constructMetaData(data.page, props.pageContext.currentPage)} 
        >




            
            <section>
                <Introduction 
                    backImg={data.page.homePageAcf.introBackground} 
                    mobBackImg={data.page.homePageAcf.introBackgroundMobile} 
                    title={data.page.homePageAcf.introHeader1}
                    par={data.page.homePageAcf.introParagraph}
                    introTab={ data.page.homePageAcf.introTab}
                />
            </section>
            <section>
                <About
                    backImg={data.page.homePageAcf.aboutBackground}
                    mobBackImg={data.page.homePageAcf.aboutBackgroundMobile} 
                    editor={data.page.homePageAcf.aboutEditor}
                    leftTab={data.page.homePageAcf.aboutLeftTab}
                    rightTab={data.page.homePageAcf.aboutRightTab}
                />
            </section>
            <section>
                <Services services={ data.services } data={data.page.homePageAcf.servicesGroup} />
            </section>
            <section>
                <CallToAction cta={data.page.homePageAcf.ctaGroup}/>
            </section>
            <section>
                <Categories data={data.page.homePageAcf.categoriesGroup} productCategories={data.productCategories} />
            </section>
            <section style={{backgroundColor: "#efefef"}}>
                <General data={data.page.homePageAcf.generalGroup} />
            </section>

        </Layout>
    )
}

export default HomeTemplate;

export const pageQuery = graphql`query GET_HOME($id: ID!) {
  wpgraphql {

    menu: template(id:"cG9zdDozMTIz", idType:ID){
        menuAcf{
          menu{
            menuGroup{
              url
              label
              isnested
              childnodes{
                url
                label
              }
            }
          }
        }  
    }

    page(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      breadcrumbAcf {
        breadcrumbName
      }
      homePageAcf {
        ...HomeIntroFragment
        ...HomeAboutFragment
        ...HomeCtaFragment
        ...CategoryCtaFragment
        ...GeneralCtaFragment
        servicesGroup {
            title
            subtitle
            backgroundImage {
                sourceUrl
                altText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
                  }
                }
            }
        }
      }
    }
    services(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
      ...ServicesFragment
    }
    footer: template(id: "cG9zdDoyMjE5") {
      id
      title
      footerInformationTemplate {
          footerContent
        address {
          addressGoogleLink
          addressLabel
        }
        hoursLabel
        hoursTitle
        informationTitle
        supportEmail
        telephoneNumber
        servicesTitle
        generalPagesTitle
      }
    }

    productCategories(where: {orderby: TERM_ORDER}) {
        edges{
            node{
                name
                slug
                categoryPageH1{
                    categoryImage{
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                              gatsbyImageData(quality: 100, layout: CONSTRAINED, width:400, placeholder: BLURRED)
                            }
                        }
                    }
                }
            }
        }
    }
  }
}
`
