import React, { useState } from 'react'
import { graphql } from 'gatsby'
import "./About.css";

function About(props) {
    const data = props;

    console.log(data)

    return (
        <div className="flex flex-wrap relative hm-ab-container">
            <div className="w-full flex items-center h-full">
                <div className="hm-tab-wrapper py-8 md:py-20">
                    <div className="hm-ab-editor px-8 md:px-0" dangerouslySetInnerHTML={{ __html: data.editor }}></div>
                </div>
            </div>
        </div>
    );
}

export default About;

export const query = graphql`fragment HomeAboutFragment on WPGraphQL_Page_Homepageacf {
  aboutEditor
}
`