import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

import "./General.css"

function General(props) {
    const data = props;
    console.log(data)
    return (
        <div className="m-auto py-20 container flex flex-wrap">
            <div className="w-full md:w-1/2 flex flex-col justify-center px-4 md:px-0">
                {data.data.title && (
                    <div>
                        <p className="text-xl" style={{ color: "#C93413" }} >
                            {data.data.subtitle}
                        </p>
                        <h2 className="text-4xl font-bold" >
                            {data.data.title}
                        </h2>
                    </div>
                )}
                <div dangerouslySetInnerHTML={{ __html: data.data.content }} className="g-editor" />
            </div>
            <div className="w-full md:w-1/2 flex pt-8 md:pt-0">
                <div className="m-auto">
                    <GatsbyImage
                        alt={data.data.image.altText}
                        loading={"lazy"}
                        image={data.data.image.imageFile.childImageSharp.gatsbyImageData}
                        formats={["auto", "webp", "avif"]}
                    // imgClassName={"cat-img"}
                    // className="flex justify-center"
                    />
                </div>
            </div>
        </div >
    );
}

export default General;

export const query = graphql`fragment GeneralCtaFragment on WPGraphQL_Page_Homepageacf {
  generalGroup {
      title
      subtitle
      content
      image {
        altText
        sourceUrl
        imageFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED, width:570, placeholder: BLURRED)
            }
        }
      }
  }
}
`