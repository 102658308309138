import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import htmlDecode from "html-entities-decoder";

import "./Services.css";


function Services(props) {
    const data = props;
    console.log(data)

    return (
        <div className="m-auto text-center">

            <div style={{ display: "grid" }}>
                {/* You can use a GatsbyImage component if the image is dynamic */}
                <GatsbyImage
                    style={{
                        gridArea: "1/1",
                        // You can set a maximum height for the image, if you wish.
                    }}
                    // You can optionally force an aspect ratio for the generated image
                    // aspectRatio={3 / 1}
                    // This is a presentational image, so the alt should be an empty string
                    alt={data.data.backgroundImage.altText}
                    // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash

                    loading={"lazy"}

                    image={data.data.backgroundImage.imageFile.childImageSharp.gatsbyImageData}

                    formats={["auto", "webp", "avif"]}
                />
                <div
                    style={{
                        // By using the same grid area for both, they are stacked on top of each other
                        gridArea: "1/1",
                        position: "relative",
                        // This centers the other elements inside the hero component
                        placeItems: "center",
                        display: "grid",
                    }}
                    className="py-20"
                >
                    {/* Any content here will be centered in the component */}
                    {data.data.title && (
                        <div>
                            <p className="text-xl" style={{color: "#DB3914"}} >
                                {data.data.subtitle}
                            </p>
                            <h2 className="text-4xl font-bold" >
                                {data.data.title}
                            </h2>
                        </div>
                    )}
                    <div className="container m-auto">
                        <div className="flex flex-wrap py-4">
                            {data.services.edges.map((service, yndex) => (
                                <div key={`service-${service.node.title}`} className="w-full sm:w-1/2 md:w-1/4 flex p-4">
                                    <Link to={`${service.node.uri.replace("service", "ypiresies")}`} className="p-4 service-tab w-full" >
                                        <GatsbyImage
                                            image={service.node.servicesAcf.smallIcon.imageFile.childImageSharp.gatsbyImageData}
                                            loading="lazy"
                                            alt={service.node.servicesAcf.smallIcon.altText}
                                            className="small-icon m-auto mt-4" />
                                        <h3 className="py-4 services-h4">
                                            {htmlDecode(service.node.title)}
                                        </h3>
                                        {/* <div dangerouslySetInnerHTML={{ __html: service.node.excerpt }} className="service-excerpt-container pb-8" /> */}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;

export const query = graphql`fragment ServicesFragment on WPGraphQL_RootQueryToServiceConnection {
  edges {
    node {
      uri
      slug
      title
      excerpt
      servicesAcf {
        smallIcon {
          altText
          sourceUrl
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 75, placeholder: BLURRED, layout: FIXED)
            }
          }
        }
      }
    }
  }
}
`