import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import arrowRight from "../../images/long-arrow-alt-right-solid.svg"

import "./Categories.css"

function Categories(props) {
    const data = props;
    console.log(data.productCategories)
    return (
        <div className="m-auto py-20 container">
            <div>
                {data.data.title && (
                    <div className="text-center">
                        <p className="text-xl" style={{color: "#DB3914"}} >
                            {data.data.subtitle}
                        </p>
                        <h2 className="text-4xl font-bold" >
                            {data.data.title}
                        </h2>
                    </div>
                )}
            </div>
            <div className="flex flex-wrap justify-center pt-4 lg:pt-12">
                {data.productCategories && data.productCategories.edges && data.productCategories.edges.map((cat, i) => (
                    <div className="w-full md:w-1/2 lg:w-1/3 px-4 pt-8 lg:pt-0" key={`p-cat-${i}`} style={{maxWidth:"400px"}}>
                        <Link to={`/${cat.node.slug}/`} className="cat-tab block">
                            <GatsbyImage
                                alt={cat?.node?.categoryPageH1?.categoryImage?.altText}
                                loading={"lazy"}
                                image={cat?.node?.categoryPageH1?.categoryImage?.imageFile?.childImageSharp?.gatsbyImageData}
                                formats={["auto", "webp", "avif"]}
                                imgClassName={"cat-img"}
                                className="cat-img-wrapper"
                            />
                            <div className="px-6 flex justify-between items-center py-4 relative" style={{top: "-4px"}}>
                                <Link to={`/${cat.node.slug}/`} >
                                    <h3 className="cat-h3">{cat.node.name}</h3>
                                </Link>
                                <Link to={`/${cat.node.slug}/`} className="flex">
                                    <div className="arrow-bttn">
                                        <img src={arrowRight} alt="βέλος δεξιά" width={18} aria-label="arrow-right" />
                                    </div>
                                </Link>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div >
    );
}

export default Categories;

export const query = graphql`fragment CategoryCtaFragment on WPGraphQL_Page_Homepageacf {
  categoriesGroup {
      title
      subtitle
  }
}
`