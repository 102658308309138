import React from 'react'
import { graphql } from 'gatsby'
import "./Introduction.css";
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"


function Introduction(props) {

    const data = props;

    console.log(props)

    return (
        <div>
            <div style={{ display: "grid" }}>
                {/* You can use a GatsbyImage component if the image is dynamic */}
                <GatsbyImage
                    style={{
                        gridArea: "1/1",
                        // You can set a maximum height for the image, if you wish.
                        maxHeight: 600,
                        minHeight: "70vh"
                    }}
                    // You can optionally force an aspect ratio for the generated image
                    // aspectRatio={3 / 1}
                    // This is a presentational image, so the alt should be an empty string
                    alt="alt"
                    // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash

                    loading={"eager"}

                    image={data.backImg.imageFile.childImageSharp.gatsbyImageData}

                    formats={["auto", "webp", "avif"]}
                />
                <div
                    style={{
                        // By using the same grid area for both, they are stacked on top of each other
                        gridArea: "1/1",
                        position: "relative",
                        // This centers the other elements inside the hero component
                        placeItems: "center",
                        display: "grid",
                    }}
                >
                    {/* Any content here will be centered in the component */}
                    <div className="px-0 sm:px-12 text-center py-8" style={{backgroundColor: "#00000073"}}>
                        <h1 className="home-h1">{data.title}</h1>
                        <p className="home-main-p py-4 hidden sm:block">{data.par}</p>
                        <div className="w-full flex justify-center">
                            <a href={`tel:${data.introTab.buttonAction}`} className="tab-bttn">
                                {data.introTab.buttonText}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Introduction;

export const query = graphql`fragment HomeIntroFragment on WPGraphQL_Page_Homepageacf {
  introBackground {
    sourceUrl
    altText
    imageFile {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
  introBackgroundMobile {
    sourceUrl
    altText
    imageFile {
      childImageSharp {
        gatsbyImageData(
          width: 600
          quality: 20
          placeholder: BLURRED
          layout: CONSTRAINED
        )
      }
    }
  }
  introHeader1
  introParagraph
  introTab {
    buttonText
    buttonAction
    tabIcon {
      sourceUrl
      altText
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 160, quality: 100, placeholder: BLURRED, layout: FIXED)
        }
      }
    }
  }
}
`